import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Flag from "react-world-flags";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

const languageMap = {
  en: { label: "English", flag: "GB", locale: "en" },
  it: { label: "Italiano", flag: "IT", locale: "it" },
  de: { label: "Deutsch", flag: "DE", locale: "de" },
  es: { label: "Español", flag: "ES", locale: "es" },
};

export default function LanguageSelect() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function setLanguage(lng) {
    i18next.changeLanguage(lng);
    setAnchorEl(null);
  }

  const selected = localStorage.getItem("i18nextLng") || "en";

  const { t } = useTranslation();

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {<Flag code={languageMap[selected].flag} height="16" />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(languageMap).map((x) => {
          return (
            <MenuItem onClick={() => setLanguage(languageMap[x].locale)}>
              <ListItemIcon>
                {<Flag code={languageMap[x].flag} height="16" />}
              </ListItemIcon>
              <ListItemText> {languageMap[x].label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
