import React, { useEffect, useState } from "react";
import "./Missions.css";
import MissionCard from "../../components/MissionCard/MissionCard";
import { db } from "../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

const Missions = () => {
  const [missions, setMissions] = useState(undefined);
  const options = {
    day: "numeric",
    month: "short",
    year: "2-digit",
  };


  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await getDocs(collection(db, "missions"));
      const missions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      missions.sort(
        (a, b) =>
          new Date(b["date"].seconds * 1000) -
          new Date(a["date"].seconds * 1000)
      );

      setMissions(missions);
    };

    fetchData().catch(console.error);
  }, []);

  function mapBoolToStatus(bool) {
    return bool ? "completed" : "upcoming";
  }

  return missions ? (
    <div className="belowNav" style={{paddingTop: "10px"}}>
      <div class="missionContainer">
        {missions.map((mission) => (
          <div className="missionCard">
            <MissionCard
              location={mission["location"]}
              hospital={mission["hospital"]}
              image={mission["imageURL"]}
              date={new Date(mission["date"].seconds * 1000).toLocaleDateString(
                undefined,
                options
              )}
              hospitalURL={mission["hospitalURL"]}
              status={mapBoolToStatus(mission["completed"])}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default Missions;
