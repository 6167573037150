import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from './locales/en/translation.json';
import itTranslation from './locales/it/translation.json';
import deTranslation from './locales/de/translation.json'
import esTranslation from './locales/es/translation.json'

const fallbackLng = ["en"];
const availableLanguages = ["en", "it", "de", "es"];

const resources = {
  en: {
    translation: enTranslation
  },
  it: {
    translation: itTranslation
  },
  de: {
    translation: deTranslation
  },
  es: {
    translation: esTranslation
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
