import React from "react";
import "./BenefactorsSponsorsList.css";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { useTranslation } from "react-i18next";

const BenefactorsSponsorsList = () => {
  const { t } = useTranslation();

  return (
    <div className="sponsors">
      <h3>{t("b&s_top_sponsors")}</h3>
      <ul>
        <li>
          <a
            href="https://www.aicpe.org/onlus-chi-siamo/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Flogo-aicpe-onlus-2022-300x300.png?alt=media&token=b7a9f035-4d6c-4d84-889c-c541a7d23c93"
              }
            ></img>
          </a>
        </li>
        <li>
          <a
            href="https://www.gompels.co.uk/who-are-we.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2FGompels-logo-GREEN.png?alt=media&token=5df9be9f-6553-4f85-9fc7-a467bf5ced3c"
              }
            ></img>
          </a>
        </li>
        <li>
          <a
            href="https://www.executivecongress.it/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Flogo-ec.png?alt=media&token=b0907976-691d-4440-88da-8f35d3c22024"
              }
            ></img>
          </a>
        </li>
        <a
          href="https://www.massiveattack.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", display: "block" }}
        >
          <li
            style={{
                //   backgroundColor: "var(--scrub-blue)",
                //   color: "white",
                color: "black",
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 5,
                textDecoration: "underline"
              }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              <b>Robert Del Naja</b>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AdsClickIcon />
            </div>
          </li>
        </a>
        <a
          href="https://www.downendbikesandscooters.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", display: "block" }}
        >
          <li
            style={{
              //   backgroundColor: "var(--scrub-blue)",
              //   color: "white",
              color: "black",
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              textDecoration: "underline"
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                paddingRight: 10,
                textAlign: "center",
              }}
            >
              <b>
                Downend Bikes <br></br> & Scooters
              </b>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AdsClickIcon />
            </div>
          </li>
        </a>
        <li className="cursive">
          <b>Julie H.</b>
        </li>
        <li className="cursive">
          <b>A. Ferguson</b>
        </li>
        <li className="cursive">
          <b>De Salvia M.</b>
        </li>
        <li className="cursive">
          <b>C. Davies</b>
        </li>
        <li className="cursive">
          <b>G. Mulumba</b>
        </li>
        <li className="cursive">
          <b>Wayne Jeffrey</b>
        </li>
      </ul>
    </div>
  );
};
export default BenefactorsSponsorsList;
