import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { NavLink, Outlet } from "react-router-dom";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import CharityNumber from "../CharityNumber/CharityNumber";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;
const navItems = [
  { translation: "navbar.home", link: "/" },
  { translation: "navbar.about-us", link: "/about" },
  { translation: "navbar.who", link: "/who-we-are" },
  { translation: "navbar.missions", link: "/missions" },
  { translation: "navbar.fundraising_events", link: "/fundraising-events" },
  { translation: "navbar.benefactors_sponsors", link: "/benefactors-and-sponsors" },
  // { translation: "navbar.contact_us", link: "/contact-us" },
];

const donateItem = { translation: "navbar.donate", link: "/donate" };

function ResponsiveAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1400));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="body2" sx={{ my: 2 }}>
        {t("navbar.charity")}: 1202741
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <NavLink
            to={item["link"]}
            exact
            activeClassName={{ color: "black" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={t(item["translation"])} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
        <NavLink
            to={donateItem["link"]}
            exact
            activeClassName={{ color: "black" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={t(donateItem["translation"])} />
              </ListItemButton>
            </ListItem>
          </NavLink>
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ bgcolor: "rgb(114, 159, 203)" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: isMobile ? "block" : "none" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NavLink
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <span>CYRENAEUS</span>
                </NavLink>
                <Box sx={{ ml: 2, display: isMobile ? "none" : "block" }}>
                  <CharityNumber />
                </Box>
              </Box>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <LanguageSelect />
            <Box sx={{ display: isMobile ? "none" : "block" }}>
              {navItems.map((item) => (
                <NavLink
                  to={item["link"]}
                  exact
                  activeClassName="active"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "inherit",
                      "&:hover": {
                        backgroundColor: "inherit",
                      },
                    }}
                  >
                    {t(item["translation"])}
                  </Button>
                </NavLink>
              ))}
            </Box>
            <Box>
              <NavLink
                to={donateItem["link"]}
                exact
                activeClassName="active"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    color: "#000",
                    backgroundColor: "#ffcc00",
                    "&:hover": {
                      backgroundColor: "#ffcc00",
                    },
                  }}
                >
                  {t(donateItem["translation"])}
                </Button>
              </NavLink>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: isMobile ? "block" : "none",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
