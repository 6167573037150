import React from "react";

import {
  Box,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";

import { Link } from "react-router-dom";

const MissionCardV2 = (props) => {
  console.log(props.mostRecent["report-url"]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card sx={{ maxWidth: 600 }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="300"
          image={props.mostRecent["imageURL"]}
        />
        <CardContent>
          {props.mostRecent["hospital"] != null &&
          props.mostRecent["hospital"] !== "" ? (
            <Typography gutterBottom variant="h5" component="div">
              {props.mostRecent["hospital"]}
            </Typography>
          ) : null}

          {props.mostRecent["location"] != null &&
          props.mostRecent["location"] !== "" ? (
            <Typography gutterBottom variant="subtitle1" component="div">
              {props.mostRecent["location"]}
            </Typography>
          ) : null}

          {props.mostRecent["description"] != null &&
          props.mostRecent["description"] !== "" ? (
            <Typography variant="body2" color="text.secondary">
              {props.mostRecent["description"]}
            </Typography>
          ) : null}
        </CardContent>
        <CardActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Button
                    component={Link}
                    to="/missions/C1Fr9EIJ735lzmMdAxT4"
                    size="small"
                  >
                    Learn More
                  </Button> */}

            {props.mostRecent["report-url"] != null &&
            props.mostRecent["report-url"] !== "" ? (
              <Button
                component={Link}
                to={props.mostRecent["report-url"]}
                size="small"
              >
                Learn More
              </Button>
            ) : (
              <Button size="small" disabled={true}>
                Learn More
              </Button>
            )}
            {props.mostRecent["date"] != null &&
            props.mostRecent["date"] !== "" ? (
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{
                  paddingRight: 2,
                }}
              >
                {new Date(
                  props.mostRecent["date"].seconds * 1000
                ).toLocaleDateString(undefined, {
                  day: "numeric",
                  month: "short",
                  year: "2-digit",
                })}
              </Typography>
            ) : null}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default MissionCardV2;
