import React from "react";
import { GivingFund, DonateSDK, BankTransfer, Stripe } from "../../components/DonationLinks/DonationLinks";

const Donate = () => {
  return (
    <div className="belowNav">
        <div className="content-grid" style={{paddingTop: "20px"}}>
            <div className="content-block"> 
                <div className="item">
                    <GivingFund/>
                </div>
                {/* <div className="item">
                    <DonateSDK/>
                </div> */}
                <div className="item">
                    <Stripe/>
                </div>
                <div className="item">
                    <BankTransfer/>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Donate;
