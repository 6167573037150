import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDdplNhY9MYYsn5w5JuuWWEojph04DrH_g",
  authDomain: "cyrenaeus-870fa.firebaseapp.com",
  projectId: "cyrenaeus-870fa",
  storageBucket: "cyrenaeus-870fa.appspot.com",
  messagingSenderId: "581573604706",
  appId: "1:581573604706:web:fc0652a598691c311c283b",
  measurementId: "G-NDRHMYKV5N",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export {app, db, storage};
