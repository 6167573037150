import React, { useEffect, useContext } from "react";
import './AboutReferences.css'
import { ReferenceContext } from "../../context/ReferenceContext/ReferenceContext";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";

const references = [
  {
    index: 1, 
    ref: "Meara, J. G., Hagander, L., & Leather, A. J. (2014). Surgery and global health: a Lancet Commission. The Lancet, 383(9911), 12-13.", 
    link: "https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(13)62345-4/fulltext?rss%3Dyes=&code=lancet-site"
  },
  {
    index: 2, 
    ref: "Roa, L., Jumbam, D. T., Makasa, E., & Meara, J. G. (2019). Global surgery and the sustainable development goals. Journal of British Surgery, 106(2), e44-e52.", 
    link: "https://bjssjournals.onlinelibrary.wiley.com/doi/pdf/10.1002/bjs.11044"
  },
  {
    index: 3, 
    ref: "Goodacre, T. E. (1986). Plastic surgery in a rural African hospital: spectrum and implications. Annals of the Royal College of Surgeons of England, 68(1), 42.", 
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2498177/pdf/annrcse01536-0047.pdf"
  },
  {
    index: 4, 
    ref: "Jamison, D. T., Breman, J. G., Measham, A. R., Alleyne, G., Claeson, M., Evans, D. B., ... & Musgrove, P. (Eds.). (2006). Disease control priorities in developing countries.", 
    link: "https://books.google.co.uk/books?hl=en&lr=&id=Ds93H98Z6D0C&oi=fnd&pg=PR7&dq=Disease+++++++++++++++++++++++Control+Priorities+in+Developing+Countries&ots=rjE4YN1Ei7&sig=Ur8jccjJpbgg52yiltcLJV1slVU&redir_esc=y#v=onepage&q=Disease%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20Control%20Priorities%20in%20Developing%20Countries&f=false"
  },
  {
    index: 5, 
    ref: "Debas, H. T., Donkor, P., Gawande, A., Jamison, D. T., Kruk, M. E., & Mock, C. N. (Eds.). (2015). Disease control priorities, (Volume 1): essential surgery. World Bank Publications.", 
    link: "https://books.google.co.uk/books?hl=en&lr=&id=jtDGBwAAQBAJ&oi=fnd&pg=PP1&dq=Disease+Control+Priorities:+Volume+++++++++++++++++++++++1:+Essential+Surgery&ots=yzFds171Dm&sig=6SoNfd0DW0IKFH2uVp12JLgZFRk&redir_esc=y#v=onepage&q=Disease%20Control%20Priorities%3A%20Volume%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%201%3A%20Essential%20Surgery&f=false"
  },
  {
    index: 6, 
    ref: "Royal College of Surgeons of England Guideline. Ethical Principles of Working Overseas.", 
    link: "https://www.rcseng.ac.uk/standards-and-research/standards-and-guidance/good-practice-guides/working-overseas/"
  },
  {
    index: 7, 
    ref: "BFIRST website, core values", 
    link: "https://bfirst.org.uk/about/core_values.aspx"
  },
]

const AboutReferences = (props) => {
  const { t } = useTranslation();
  const { selectedReference, setSelectedReference } = useContext(ReferenceContext);
  const refs = references.reduce((acc, value) => {
    acc[value.index] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (selectedReference) {
      refs[selectedReference].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      const timeoutId = setTimeout(() => {
        setSelectedReference(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
}, [selectedReference]);

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("about-us.references")}</legend>
        <div className="item">
        <List>
            {references.map((reference) => (
              <ListItem ref={refs[reference.index]} key={reference.index}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "10px" }}
                  primary={
                    <>
                      <span style={{ color: "black" }}>{`${reference.index}. `}</span>
                      <Link href={reference.link} className={selectedReference === reference.index ? 'highlight' : ''} target="_blank" rel="noopener">
                        {reference.ref}
                      </Link>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </fieldset>
    </div>
  );
};

export default AboutReferences;
