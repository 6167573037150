import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Missions from "./pages/Missions/Missions";
import NoPage from "./pages/NoPage/NoPage";
import "./App.css";

import "./styles/fieldset.css";
import ResponsiveAppBar from "./components/AppBar/AppBar";
import Who from "./pages/Who/Who";
import Donate from "./pages/Donate/Donate";
import AboutUs from "./pages/AboutUs/AboutUs";
import BenefactorsSponsors from "./pages/BenefactorsSponsors/BenefactorsSponsors";
import FundraisingEvents from "./pages/FundraisingEvents/FundraisingEvents";
import MissionReport from "./pages/MissionReport/MissionReport";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ResponsiveAppBar />}>
          <Route index element={<Home />} />
          <Route path="donate" element={<Donate />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="missions" element={<Missions />} />
          <Route path="missions/:missionID" element={<MissionReport />} />
          <Route path="who-we-are" element={<Who />} />
          <Route path="fundraising-events" element={<FundraisingEvents/>} />
          <Route path="benefactors-and-sponsors" element={<BenefactorsSponsors/>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
