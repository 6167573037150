import React from "react";
import "./NoPage.css";
import HittingHammer from "../../components/HittingHammer/HittingHammer";

const NoPage = () => {
  return (
    <div className="container">
      <HittingHammer />
      <p>
        Cyrenaeus's website is currently under construction. We're doing our best to get everything up and running.
      </p>
    </div>
  );
};

export default NoPage;
