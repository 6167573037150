import React from "react";
import "./MissionCard.css";
import { Grid, Box, Button } from "@mui/material";
import Countdown from "../Countdown/Countdown";

const MissionCard = (props) => {
  let statusClass = "";

  switch (props.status) {
    case "upcoming":
      statusClass = "status-text upcoming";
      break;
    case "completed":
      statusClass = "status-text completed";
      break;
    default:
      statusClass = "status-text pending";
      break;
  }

  return (
    <div className="mission-card">
      <Grid container direction="column">
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ height: "100%" }}
          >
            <div className="mission-location">{props.location}</div>
          </Box>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <div className="sub-text" style={{paddingLeft: "10px"}}>{props.hospital}</div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <div className="sub-text" style={{paddingRight: "10px"}}>
                {props.rawDate ? (
                  <Countdown targetDate={props.rawDate} />
                ) : null}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <div className="image-container">
        <img src={props.image} alt={props.location} className="mission-image" />
      </div>
      <Grid
        container
        sx={{ padding: { xs: "0.5rem", sm: "0.75rem", md: "1rem" } }}
      >
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <div className="mission-date">{props.date}</div>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <a
              href={props.hospitalURL}
              className="hospital-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="text">view hospital</Button>
            </a>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <div className={statusClass}>{props.status}</div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MissionCard;
