import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from '@mui/icons-material/Circle';
import { ReferenceContext } from "../../context/ReferenceContext/ReferenceContext";

const AboutNeed = () => {
  const { t } = useTranslation();
  const { setSelectedReference } = useContext(ReferenceContext);

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("about-us.need")}</legend>
        <div className="item">
          <List>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "red",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span>{t("about-us.need.1")}</span>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(1)}>1</sup>
                    <sup>,</sup>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(2)}>2</sup>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "red", width: "15px" }} />
              </ListItemIcon>
              <ListItemText primary={t("about-us.need.2")} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "red",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span>{t("about-us.need.3")}</span>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(3)}>3</sup>
                    <sup>,</sup>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(4)}>4</sup>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "red",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span>{t("about-us.need.4")}</span>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(5)}>5</sup>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "red",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText primary={t("about-us.need.5")} />
            </ListItem>
          </List>
        </div>
      </fieldset>
    </div>
  );
};

export default AboutNeed;
