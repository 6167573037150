import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  Switch,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopy from "@mui/icons-material/ContentCopy";
import PayPalDonateButton from "../PayPalDonateButton/PayPalDonateButton";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--scrub-blue)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const GivingFund = () => {
  const { t } = useTranslation();

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("donate.giving.title")}</legend>
        <div className="item">
          <div className="centred-text">{t("donate.giving.description")}</div>
          <div className="centred-text">
            <div style={{ paddingTop: "10px" }}>
              <a
                href="https://paypal.com/gb/fundraiser/charity/4826330"
                target="_blank"
              >
                <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" />
              </a>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const DonateSDK = () => {
  const { t } = useTranslation();
  const [taxpayer, setTaxpayer] = useState(false);
  const [giftaid, setGiftaid] = useState(false);

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("donate.paypal.title")}</legend>
        <div className="item">
          <div className="centred-text">{t("donate.paypal.description")}</div>
        </div>

        <div
          className="item"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div
            className="centred-text"
            style={{ color: "var(--scrub-blue)", fontWeight: "bolder" }}
          >
            Are you a UK tax payer?
          </div>
          <div className="centred-text">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <AntSwitch
                checked={taxpayer}
                onChange={(event) => setTaxpayer(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </div>
        </div>

        <div className="item">
          <div className="centred-text">
            <PayPalDonateButton giftaid={taxpayer} />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const Stripe = () => {
  const { t } = useTranslation();

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("donate.stripe.title")}</legend>
        <div className="item">
          <div className="centred-text">{t("donate.stripe.description")}</div>
          <div className="centred-text">
            <div style={{ paddingTop: "10px" }}>
              <a
                href="https://donate.stripe.com/7sI9E0g5L0tx8Zq7ss"
              >
                <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" />
              </a>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const BankTransfer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [bank, setBank] = useState([
    {
      text: "68824095",
      label: "Account Number",
    },
    {
      text: "08-92-50",
      label: "Sort Code",
    },
    {
      text: "GB34CPBK08925068824095",
      label: "IBAN",
    },
    {
      text: "CPBKGB21CCB",
      label: "BIC",
    },
  ]);

  const handleCopy = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("donate.transfer.title")}</legend>

        {bank.map((x) => {
          return (
            <div className="item" style={{ padding: "5px" }}>
              <div className="centred-text"></div>
              <TextField
                fullWidth
                label={x.label}
                defaultValue={x.text}
                variant="standard"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard text={x.text}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleCopy}
                          edge="end"
                        >
                          <ContentCopy />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        })}
      </fieldset>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="copied"
        sx={{ width: "auto" }}
      />
    </div>
  );
};

export { GivingFund, DonateSDK, Stripe, BankTransfer };
