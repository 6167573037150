import React, { useState } from "react";
import "./AboutUs.css";
import { useTranslation } from "react-i18next";
import Div100vh from "react-div-100vh";
import AboutNeed from "../../components/AboutNeed/AboutNeed";
import AboutValue from "../../components/AboutValue/AboutValue";
import AboutReferences from "../../components/AboutReferences/AboutReferences";
import { ReferenceContext } from "../../context/ReferenceContext/ReferenceContext";

const AboutUs = () => {
  const { t } = useTranslation();
  const [selectedReference, setSelectedReference] = useState(null);

  return (
    <Div100vh>
      <ReferenceContext.Provider
        value={{ selectedReference, setSelectedReference }}
      >
        <div className="belowNav">
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <h1 class="cut-text">{t("about-us.project.title")}</h1>

            <div className="content-grid">
              <div className="project-description-container">
                <div className="project-description-text">
                  {t("about-us.project.description")}
                </div>
              </div>
              <div className="content-block">
                <div className="item inner-right">
                  <AboutNeed />
                </div>
              </div>
              <div className="content-block">
                <div className="item inner-left">
                  <AboutValue />
                </div>
              </div>
            </div>

            <div className="item" style={{padding: "10px"}}>
              <AboutReferences />
            </div>
          </div>
        </div>
      </ReferenceContext.Provider>
    </Div100vh>
  );
};

export default AboutUs;
