import React, { useEffect, useRef } from "react";
import "./BenefactorsSponsors.css";
import { animateScroll as scroll } from "react-scroll";
import Div100vh from "react-div-100vh";
import { useTranslation } from "react-i18next";
import BenefactorsSponsorsList from "../../components/BenefactorsSponsorsList/BenefactorsSponsorsList";

const BenefactorsSponsors = () => {
  const { t } = useTranslation();

  const bottomRef = useRef();

  const isDesktop = window.matchMedia("(min-width: 900px)");
  const offset = isDesktop.matches ? 65 : 55;

  useEffect(() => {
    setTimeout(() => {
      const rect = bottomRef.current.getBoundingClientRect();
      scroll.scrollTo(rect.top - offset, { duration: 2000 });
    }, 2000);
  }, []);

  return (
    <Div100vh>
      <div className="belowNav">
        <div className="thank-you-top">
          <div className="thank-you-image" />

          <div className="thank-you-text">
            <div className="zoom-in-out-box">{t("b&s_thank_you")}</div>
          </div>

          <div className="thank-you-heart">♥</div>
        </div>
        <div className="thank-you-bottom" ref={bottomRef}>
          <div className="thank-you-message">
            {t("b&s_message")} <b>{t("b&s_message_bold")}</b>
          </div>
          <div className="sign-off">
            {t("b&s_sincerely")} <br />
            <b>
              <span className="sign-off-text">{t("b&s_team_cyrenaeus")}</span>
            </b>
          </div>

          <div className="divider" />

          <BenefactorsSponsorsList/>
        </div>
      </div>
    </Div100vh>
  );
};

export default BenefactorsSponsors;
