import React, { useState, useRef, useEffect } from "react";
import "./FlipCard.css";
import ReactCardFlip from "react-card-flip";
import { Grid, Box, Button } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import InfoIcon from "@mui/icons-material/Info";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FlipCard = (props) => {
  let textColour = {
    Trustee: "#ff0000",
    Clinician: "#00ccff",
    Chairman: "#007bff",
    "Web Master": "#00cc66",
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const div = scrollableRef.current;
    setIsScrollable(div.scrollHeight > div.clientHeight);
  }, [props.biography]);

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const scrollableRef = useRef(null);

  const checkScroll = () => {
    const div = scrollableRef.current;
    if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  const scrollDown = () => {
    const div = scrollableRef.current;
    const amountToScroll = 330;
    div.scrollTo({ top: div.scrollTop + amountToScroll, behavior: "smooth" });
  };

  const scrollTop = () => {
    const div = scrollableRef.current;
    div.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 600) {
      setWidth(600);
    } else {
      setWidth(window.innerWidth);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.1) {
      setZoomLevel(zoomLevel - 0.1);
    }
  };

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      className=""
      onClick={handleClick}
    >
      <div className="flip-card flip-card-front" onClick={handleClick}>
        <div className="name">{props.name}</div>
        <div className="flip-image-container">
          <img
            src={props.imageSource}
            alt="Avatar"
            style={{ width: "300px", height: "310px", objectFit: "cover" }}
          />
        </div>

        <Grid container>
          <Grid item xs={5}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ height: "100%", paddingLeft: "5px" }}
            >
              {props.biography !== "" || props.cvLink !== "" ? (
                <IconButton onClick={handleClick}>
                  <InfoIcon />
                </IconButton>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <div className="badges">
                {props.roles.map((badge) => {
                  if (badge !== "Advisory Board") {
                    return (
                      <span
                        className="badge"
                        style={{ color: textColour[badge] }}
                      >
                        {badge}
                      </span>
                    );
                  }
                })}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="flip-card flip-card-back">
        <Grid container direction="column">
          <Grid item xs={7} onClick={handleClick}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "350px" }}
            >
              <div
                className="biography"
                ref={scrollableRef}
                onScroll={checkScroll}
              >
                {props.biography}
              </div>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Grid container alignItems="center" sx={{ height: "50px" }}>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-start">
                  <Button variant="text" onClick={handleClick}>
                    <ChevronLeftOutlinedIcon sx={{ color: "white" }} />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="center">
                  {isScrollable &&
                    (isScrolledToBottom ? (
                      <Button variant="text" onClick={scrollTop}>
                        <ExpandLessIcon sx={{ color: "white" }} />
                      </Button>
                    ) : (
                      <Button variant="text" onClick={scrollDown}>
                        <ExpandMoreIcon sx={{ color: "white" }} />
                      </Button>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  {props.cvLink !== "" ? (
                    <Button
                      variant="text"
                      onClick={handleOpen}
                      sx={{ color: "white", marginRight: "5px" }}
                    >
                      View CV
                    </Button>
                  ) : null}
                </Box>
              </Grid>

              <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={true}
                ref={ref}
              >
                <DialogTitle
                  sx={{
                    m: 0,
                    p: 2,
                    bgcolor: "var(--scrub-blue)",
                    color: "white",
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      color: "white",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent ref={ref} sx={{ bgcolor: "#e0e0e0" }}>
                  <Document
                    file={props.cvLink}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="document"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        pageNumber={index + 1}
                        key={`page_${index + 1}`}
                        scale={zoomLevel}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        customTextRenderer={false}
                        className="page"
                        width={width - 50}
                      />
                    ))}
                  </Document>
                </DialogContent>
                <DialogActions
                  sx={{
                    bgcolor: "var(--scrub-blue)",
                  }}
                >
                  <IconButton
                    sx={{
                      color: "white",
                    }}
                    onClick={handleZoomIn}
                  >
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: "white",
                    }}
                    onClick={handleZoomOut}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ReactCardFlip>
  );
};

export default FlipCard;
