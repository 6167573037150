import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReferenceContext } from "../../context/ReferenceContext/ReferenceContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from '@mui/icons-material/Circle';

const AboutValue = () => {
  const { t } = useTranslation();
  const { setSelectedReference } = useContext(ReferenceContext);

  return (
    <div className="itemContent">
      <fieldset className="fieldsetContainer">
        <legend className="legendText">{t("about-us.value")}</legend>
        <div className="item">
          <List>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "blue", width: "15px" }} />
              </ListItemIcon>
              <ListItemText primary={t("about-us.value.1")} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "blue", width: "15px" }} />
              </ListItemIcon>
              <ListItemText primary={t("about-us.value.2")}></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "blue",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText primary={t("about-us.value.3")} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon sx={{ color: "blue",  width: "15px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span>{t("about-us.value.4")}</span>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(6)}>6</sup>
                    <sup>,</sup>
                    <sup style={{ color: "blue" }} onClick={() => setSelectedReference(7)}>7</sup>
                  </>
                }
              />
            </ListItem>
          </List>
        </div>
      </fieldset>
    </div>
  );
};

export default AboutValue;
