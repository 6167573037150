import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React from "react";

const FundraisingEvents = () => {
  return (
    <div className="belowNav">
      <Box pt={2}>
        <Container>
          <Card sx={{ maxWidth: 500, margin: "0 auto" }}>
            <div
              style={{
                position: "relative",
                paddingBottom: "60%",
                height: 0,
              }}
            >
              <CardMedia
                sx={{
                  position: "absolute",
                  top: 8,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                component="img"
                image={
                  "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fsalvage-crew.png?alt=media&token=b0c44b36-3de5-494b-9413-ad4317e64b88"
                }
              />
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Salvage Crew
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Saturday 2nd September, 7:30pm
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                color="text.secondary"
              >
                St Albans Church Hall, Redland, BS6 7NS
              </Typography>
              <Typography mt={2} variant="body2" color="darkgrey">
                £12 per person entry donation with all proceeds going to
                Cyrenaeus.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => {
                  window.location.href =
                    "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/Charity%20Gig%20Flyer.pdf?alt=media&token=beb51d12-ab22-45e6-a99b-10aac0083607";
                }}
              >
                More info
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default FundraisingEvents;
