import React, { useState, useEffect } from "react";
import "./Home.css";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { setDefaults, useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  LinkedIn,
  EmailOutlined,
} from "@mui/icons-material";
import { db } from "../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import MissionCardV2 from "../../components/MissionCardV2/MissionCardV2";

const Home = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const xsIcon = "15vw";
  const smIcon = "11vw";
  const mdIcon = "7vw";
  const lgIcon = "100px";

  const [defaults, setDefaults] = useState(null);
  const [mostRecent, setMostRecent] = useState(null);
  const [upcoming, setUpcoming] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await getDoc(doc(db, "defaults", "home"));
      setDefaults(snapshot.data());
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const mostRecentSnapshot = await getDoc(
        doc(db, "missions", defaults["most-recent"])
      );
      setMostRecent(mostRecentSnapshot.data());

      const upcomingSnapshot = await getDoc(
        doc(db, "missions", defaults["upcoming"])
      );
      setUpcoming(upcomingSnapshot.data());
    };

    fetchData().catch(console.error);
  }, [defaults]);

  return (
    <Box sx={{ paddingTop: isSmallScreen ? "55px" : "60px" }}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ImageCarousel />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Container sx={{ paddingTop: { md: 0, lg: 5 } }}>
            <Box sx={{ padding: 2, textAlign: "center" }}>
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                sx={{ color: "rgb(114, 159, 203)" }}
                gutterBottom
              >
                {t("home.introduction.title")}
              </Typography>
              <Typography variant={isSmallScreen ? "h6" : "h5"} gutterBottom>
                {t("home.introduction.description")}
              </Typography>
              <NavLink
                to={"/about"}
                exact
                activeClassName="active"
                style={{ textDecoration: "none" }}
              >
                <Button sx={{ paddingBottom: 2 }}>
                  <Typography variant="button">
                    {t("home.introduction.readMore")}
                  </Typography>
                </Button>
              </NavLink>
            </Box>
          </Container>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="stretch" sx={{ marginTop: 0 }}>
        <Grid
          item
          lg={12}
          xl={4}
          sx={{
            bgcolor: "rgb(114, 159, 203)",
            width: "100%",
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingBottom: 2,
            }}
          >
            <video
              autoPlay
              loop
              muted
              controls
              style={{ maxWidth: "300px", height: "auto" }}
            >
              <source
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/videos%2F0816.mov?alt=media&token=79283aae-8604-49b8-b170-fca818bbe149"
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Container>
        </Grid>

        <Grid item lg={12} xl={8}>
          <Grid container justifyContent="center" spacing={2}>
            {upcoming != null ? (
              <Grid item md={12} lg={6} xl={6}>
                <Container>
                  <Box sx={{ padding: 2, textAlign: "center" }}>
                    <Typography
                      variant={isSmallScreen ? "h4" : "h3"}
                      sx={{ color: "rgb(114, 159, 203)" }}
                      gutterBottom
                    >
                      Upcoming mission:
                    </Typography>
                  </Box>
                  <MissionCardV2 mostRecent={upcoming} />
                </Container>
              </Grid>
            ) : null}
            {mostRecent != null ? (
              <Grid item md={12} lg={6} xl={6} justifyContent={"center"}>
                <Container>
                  <Box sx={{ padding: 2, textAlign: "center" }}>
                    <Typography
                      variant={isSmallScreen ? "h4" : "h3"}
                      sx={{ color: "rgb(114, 159, 203)" }}
                      gutterBottom
                    >
                      Most recent mission:
                    </Typography>
                  </Box>
                  <MissionCardV2 mostRecent={mostRecent} />
                </Container>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Paper sx={{ margin: 2, padding: 2, textAlign: "center" }} elevation={3}>
        <IconButton
          aria-label="Facebook"
          href="https://www.facebook.com/profile.php?id=100093034806609"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "#1877F2" }}
        >
          <Facebook
            sx={{
              fontSize: { xs: xsIcon, sm: smIcon, md: mdIcon, lg: lgIcon },
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Instagram"
          href="https://www.instagram.com/cyrenaeus"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "#E4405F" }}
        >
          <Instagram
            sx={{
              fontSize: { xs: xsIcon, sm: smIcon, md: mdIcon, lg: lgIcon },
            }}
          />
        </IconButton>
        <IconButton
          aria-label="LinkedIn"
          href="https://www.linkedin.com/company/cyrenaeus/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "#0A66C2" }}
        >
          <LinkedIn
            sx={{
              fontSize: { xs: xsIcon, sm: smIcon, md: mdIcon, lg: lgIcon },
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Email"
          href="mailto:admin@cyrenaeus.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "#818589" }}
        >
          <EmailOutlined
            sx={{
              fontSize: { xs: xsIcon, sm: smIcon, md: mdIcon, lg: lgIcon },
            }}
          />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default Home;
