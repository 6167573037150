import React, {useState} from "react";
import './CharityNumber.css'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CharityNumber = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const toggleContentHeight = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div class={collapsed ? "charity-info" : "charity-info collapsed"}>
              <div
                className={
                  collapsed
                    ? "charity-info-number"
                    : "charity-info-number collapsed"
                }
              >
                {t('navbar.charity')}: 1202741
              </div>
              <div
                className={
                  collapsed
                    ? "charity-info-button"
                    : "charity-info-button collapsed"
                }
                onClick={toggleContentHeight}
              >
                <FontAwesomeIcon
                  icon={collapsed ? faChevronLeft : faChevronRight}
                />
              </div>
            </div>
  );
};

export default CharityNumber;