import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageCarousel.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

const images = [
  require("../../images/image1.jpeg"),
  require("../../images/image2.jpeg"),
  require("../../images/image3.jpeg"),
  require("../../images/image4.jpeg"),
  require("../../images/image10.jpg"),
];

function ImageCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  let sliderHeight = window.innerHeight/1.5 - 80;

  const slider = useRef(null);

  return (
    <div className="carousel-container">
      <button className="carousel-button prev" onClick={() => slider?.current?.slickPrev()}>
        <ChevronLeftOutlinedIcon />
      </button>
      <button className="carousel-button next" onClick={() => slider?.current?.slickNext()}>
        <ChevronRightOutlinedIcon />
      </button>
      <Slider ref={slider} {...settings} className="slider">
        {images.map((imageUrl) => (
          <div
            className="carousel-image-container"
            style={{ height: `${sliderHeight}px` }}
          >
            <img
              className="carousel-image"
              style={{ height: `${sliderHeight}px` }}
              src={imageUrl}
              alt="carousel"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}


export default ImageCarousel;
