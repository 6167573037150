import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import "./Who.css";
import FlipCard from "../../components/FlipCard/FlipCard";
import { db } from "../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const Who = () => {
  const { t, i18n } = useTranslation();
  const [friends, setFriends] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await getDocs(collection(db, "friends"));
      const friends = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      for (const friend of friends) {
        const nestedCollectionSnapshot = await getDocs(
          collection(db, `friends/${friend.id}/translations`)
        );
        const nestedCollection = {};
        for (const nestedDoc of nestedCollectionSnapshot.docs) {
          nestedCollection[nestedDoc.id] = nestedDoc.data();
        }
        friend.translations = nestedCollection;
      }

      setFriends(friends);
      setRoles(
        friends
          .map((x) => x.roles)
          .flat()
          .map((x) => {
            return { name: x, tick: true };
          })
      );
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (friends) {
      setFilteredFriends(
        friends.filter((friend) =>
          friend.roles.some((role) =>
            roles.find((r) => r.name === role && r.tick)
          )
        )
      );
    }
  }, [friends, roles]);

  const handleRoleChange = (updatedRole) => {
    setRoles(
      roles.map((role) => {
        if (role.name === updatedRole.name) {
          return updatedRole;
        }
        return role;
      })
    );
  };

  return (
    <Div100vh>
      <div className="belowNav" style={{ paddingTop: "10px" }}>
        {/* <div className="friends-title">
          <div></div>
          <div className="friends-title-text"></div>
          <div>
            {roles.length > 0 ? (
              <FilterFriends roles={roles} onRoleChange={handleRoleChange} />
            ) : null}
          </div>
        </div> */}

        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">{t("who.advisory")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="friends-container">
              {friends
                ? friends
                    .filter((x) => x.roles.includes("Advisory Board"))
                    .map((x) => {
                      return (
                        <div className="friend-card">
                          <FlipCard
                            name={x.name}
                            imageSource={x.imageSource}
                            roles={x.roles}
                            biography={x.translations.biography[i18n.language]}
                            cvLink={x.cvLink}
                          />
                        </div>
                      );
                    })
                : null}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">{t("who.clinician")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="friends-container">
              {friends
                ? friends
                    .filter((x) => x.roles.includes("Clinician"))
                    .map((x) => {
                      return (
                        <div className="friend-card">
                          <FlipCard
                            name={x.name}
                            imageSource={x.imageSource}
                            roles={x.roles}
                            biography={x.translations.biography[i18n.language]}
                            cvLink={x.cvLink}
                          />
                        </div>
                      );
                    })
                : null}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Div100vh>
  );
};

export default Who;
